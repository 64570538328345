import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
        },
        {
          path: "/complaints",
          name: "complaints",
          component: () => import("@/view/pages/Complaints/Listing.vue"),
        },

        {
          path: "/accounts",
          name: "accounts",
          component: () => import("@/view/pages/Accounts/Listing.vue"),
        },
        {
          path: "/payments",
          name: "payments",
          component: () => import("@/view/pages/Payments/Listing.vue"),
        },

        {
          path: "/send-payment",
          name: "send-payment",
          component: () => import("@/view/pages/Payments/Add.vue"),
        },

        {
          path: "/privacypolicy",
          name: "privacypolicy",
          component: () =>
            import("@/view/pages/Settings/privacyPolicy/Add.vue"),
        },
        {
          path: "/contactus",
          name: "contactus",
          component: () =>
            import("@/view/pages/Settings/contactUs/Listing.vue"),
        },

        {
          path: "/stripesetting",
          name: "stripesetting",
          component: () =>
            import("@/view/pages/Settings/stripeSetting/Listing.vue"),
        },

        {
          path: "/skippercentage",
          name: "skippercentage",
          component: () =>
            import("@/view/pages/Settings/skipSharePercentage/Listing.vue"),
        },

        {
          path: "/builder",
          name: "builder",
          component: () => import("@/view/pages/Builder.vue"),
        },

        {
          path: "/user",
          name: "user",
          component: () => import("@/view/user/User.vue"),
        },
        {
          path: "/adduser",
          name: "adduser",
          component: () => import("@/view/user/Add.vue"),
        },
        {
          path: "/edituser",
          name: "edituser",
          component: () => import("@/view/user/Edit.vue"),
        },

        {
          path: "/deletedusers",
          name: "deletedusers",
          component: () => import("@/view/user/Deleted.vue"),
        },
        {
          path: "/skipshare",
          meta: { title: "skipshare" },
          name: "skipshare",
          component: () => import("@/view/pages/skiptoshare/SkipShare.vue"),
        },

        {
          path: "/deletedskip",
          meta: { title: "deletedskip" },
          name: "deletedskip",
          component: () => import("@/view/pages/skiptoshare/Deleted.vue"),
        },
        {
          path: "/skiprequest",
          name: "skiprequest",
          component: () => import("@/view/pages/skiptoshare/RequestView.vue"),
        },

        {
          path: "/paidrequestlist",
          name: "paidrequestlist",
          component: () =>
            import("@/view/pages/skiptoshare/PaidRequestList.vue"),
        },

        {
          path: "/specificpaidrequestlist",
          name: "specificpaidrequestlist",
          component: () =>
            import("@/view/pages/skiptoshare/SpecificPaidRequest.vue"),
        },

        {
          path: "/paymentreceivedskiplist",
          name: "paymentreceivedskiplist",
          component: () =>
            import("@/view/pages/skiptoshare/PaymentReceivedSkipList.vue"),
        },
        {
          path: "/addskip",
          name: "addskip",
          component: () => import("@/view/pages/skiptoshare/Add.vue"),
        },
        {
          path: "/skipdetail",
          name: "skipdetail",
          component: () => import("@/view/pages/skiprequest/SkipDetail.vue"),
        },

        {
          path: "/editskip",
          name: "editskip",
          component: () => import("@/view/pages/skiptoshare/Edit.vue"),
        },

        {
          path: "/skip-request-listing",
          name: "skip-request-listing",
          component: () => import("@/view/pages/skiprequest/Listing.vue"),
        },

        {
          path: "/skip-request-add",
          name: "skip-request-add",
          component: () => import("@/view/pages/skiprequest/Add.vue"),
        },

        {
          path: "/skip-request-edit",
          name: "skip-request-edit",
          component: () => import("@/view/pages/skiprequest/Edit.vue"),
        },

        {
          path: "/pushnotificationsend",
          name: "pushnotificationsend",
          component: () => import("@/view/pages/Notifications/Add.vue"),
        },

        {
          path: "/notifications",
          name: "notifications",
          component: () => import("@/view/pages/Notifications/Listing.vue"),
        },

        {
          path: "/user-list",
          name: "user-list",
          component: () =>
            import("@/view/pages/Notifications/AssignedUser.vue"),
        },

        {
          path: "/wizard",
          name: "wizard",
          component: () => import("@/view/pages/wizard/Wizard.vue"),
          children: [
            {
              path: "wizard-1",
              name: "wizard-1",
              component: () => import("@/view/pages/wizard/Wizard-1.vue"),
            },
            {
              path: "wizard-2",
              name: "wizard-2",
              component: () => import("@/view/pages/wizard/Wizard-2.vue"),
            },
            {
              path: "wizard-3",
              name: "wizard-3",
              component: () => import("@/view/pages/wizard/Wizard-3.vue"),
            },
            {
              path: "wizard-4",
              name: "wizard-4",
              component: () => import("@/view/pages/wizard/Wizard-4.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue"),
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue"),
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue"),
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue"),
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue"),
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login"),
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/Register"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
  ],
});
